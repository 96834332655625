/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styles */
body {
  font-family: Arial, sans-serif;
}

/* Hero section styles */
.hero {
  background-image: url('hako-under-maintenance.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  height: 100vh; /* Mengisi tinggi layar */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  max-width: 600px;
}